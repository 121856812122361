
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --hf-background: 244, 244, 245;
    --hf-foreground: 255, 255, 255;
    --hf-input: 9, 9, 11, 15%;
    --hf-primary: 9, 9, 11;
    --hf-primary-light: 255, 255, 255;
    --hf-secondary: 113, 113, 122;
    --hf-secondary-light: 9, 9, 11, 2.5%;
    --hf-accent: 230, 230, 230;
    --hf-accent-light: 9, 9, 11, 5%;
    --hf-error: 220, 38, 38;
  }

  .dark {
    --hf-background: 9, 9, 11;
    --hf-foreground: 24, 24, 27;
    --hf-input: 255, 255, 255, 10%;
    --hf-primary: 255, 255, 255;
    --hf-primary-light: 24, 24, 27;
    --hf-secondary: 161, 161, 170;
    --hf-secondary-light: 255, 255, 255, 2.5%;
    --hf-accent: 230, 230, 230;
    --hf-accent-light: 255, 255, 255, 5%;
    --hf-error: 220, 38, 38;
  }
}

body {
  @apply font-sans text-primary antialiased w-screen overflow-x-hidden;
}

.hero-bg {
    background-image: radial-gradient(85% 45% at 50% 0, #fff 75%, transparent), radial-gradient(65% 55% at 50% 25%, #fff 35%, transparent), linear-gradient(130.5deg, #f9ddf3 12.74%, #c4f7ff 89.62%);
}

/* Testimonial */
@keyframes trotting {
    to {
        transform: translateY(-50%);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.testimonials {
    .testimonial-column {
        animation-play-state: running;
    }

    &:hover {
        .testimonial-column {
            animation-play-state: paused;
        }
    }
}

.testimonial-column {
    animation: trotting var(--trotting-duration) linear infinite;
}

.testimonial-column-0 {
    --trotting-duration: 24s;
}

.testimonial-column-1 {
    @apply hidden md:flex;
    --trotting-duration: 30s;
}

.testimonial-column-2 {
    @apply hidden lg:flex;
    --trotting-duration: 26s;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.faq ul {
  list-style-type: disc;
  padding-left: 20px;
}

.progressbar-progress {
    border-radius: 8px;
    background: linear-gradient(to right, #FE81DA, #FBA7E3) !important;
  }
  

  
/* } */

.pageTitle {
    @apply text-4xl md:text-7xl font-extrabold mb-4 text-center;
}

.pageDesc {
    @apply text-xl text-slate-700 text-center;
}

.ctaButton {
    @apply px-6 py-3 bg-slate-950 rounded-xl font-medium text-lg text-white;
}

.sectionTitle {
    @apply mt-4 text-2xl lg:text-5xl font-bold text-black leading-none;
}
.sectionDesc {
    @apply max-w-3xl mt-6 text-xl lg:text-2xl md:text-4xl leading-normal font-normal text-slate-700;
}

.postContent {
    @apply text-lg;
}

.postContent p {
    @apply pb-6 text-slate-800 text-lg;
}
.postTitle {
    @apply text-2xl md:text-3xl font-bold mt-8 mb-4;
}

.template-content {
    p {
        @apply mb-4;
    }

    h2 {
        @apply mt-8 mb-6 lg:text-4xl text-2xl text-slate-950 font-semibold;
    }

    h3 {
        @apply mt-6 mb-4 lg:text-2xl text-lg text-slate-950 font-semibold;
    }

    h4 {
        @apply mt-4 mb-2 lg:text-xl text-base text-slate-950 font-semibold;
    }

    ul {
        @apply list-disc ml-8 mb-4;
    }

    li {
        @apply my-4;
    }

    img {
        @apply my-4 max-w-full h-auto align-middle;
    }
}